import React from 'react';
import { Router } from '@reach/router';
import RedeemPage from './redeem';
import Layout from './layout';


const Routes = () => (
  <Router>
    <Layout path="/">
      <RedeemPage path="/" />
    </Layout>
  </Router>
);

export default Routes;
