import React from 'react';
import { Message, Container, Segment } from 'semantic-ui-react';
import Iframe from 'react-iframe';
import * as queryString from 'query-string';

const Error = () => (
  <Container text>
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>Your redemption code could not be retrieved.</p>
    </Message>
  </Container>
);

const RedeemPage = () => {
  const qString = queryString.parse(location.search); // eslint-disable-line
  const { code, url } = qString;
  let fullUrl;
  if (code) {
    fullUrl = `https://esuite.eflyn.com/player/spin/qrcode.php?code=${code}`;
  } else if (url) {
    fullUrl = url;
  } else {
    return (<Error />);
  }
  return (
    <Container>
      <Segment>
        <Iframe
          url={fullUrl}
          display="initial"
          position="relative"
          frameBorder={0}
          height="400"
          width="100%"
        />
      </Segment>
    </Container>
  );
};

export default RedeemPage;
