import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'semantic-ui-react';
import * as queryString from 'query-string';

function getLogo() {
  let url = '/assets/img/logo.png';
  const qString = queryString.parse(location.search); // eslint-disable-line
  const { logo } = qString;
  if (logo) {
    url = `/assets/img/logos/${logo}.png`;
  }
  return `https://vegas.planaday.com${url}`;
}

const Layout = props => {
  const { children } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="main-container">
      <main style={{ flex: '1 0 auto', textAlign: 'center' }} className="layout-container">
        <Menu size="massive">
          <img src={getLogo()} alt="Planaday Logo" height="70" style={{ margin: 'auto', padding: '5px 0' }} />
        </Menu>
        <div style={{ position: 'relative' }}>
          { children }
        </div>
      </main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
};

export default Layout;
